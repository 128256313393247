<template>
    <div :id="name" class="ntriga-modal-overlay text-left">
        <a href="#" class="cancel"></a>

        <div class="ntriga-modal">
            <slot></slot>

            <footer class="text-right mt-3">
                <slot name="footer"></slot>
            </footer>

            <a href="#" class="close">&times;</a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['name']
    }
</script>

<style>
    .ntriga-modal-overlay {
        visibility: hidden;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .4);
        transition: opacity .3s;
        opacity: 0;
        z-index: 99999999;
    }

    .ntriga-modal-overlay:target {
        visibility: visible;
        opacity: 1;
    }

    .ntriga-modal {
        position: relative;
        width: 500px;
        max-width: 80%;
        background: white;
        border-radius: 4px;
        padding: 2.5em;
        box-shadow: 0 5px 11px rgba(36, 37, 38, 0.08);
    }

    .ntriga-modal .close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: grey;
        text-decoration: none;
    }

    .ntriga-modal-overlay .cancel {
        position: absolute;
        width: 100%;
        height: 100%;
    }
</style>